<template>
  <div>
    <div class="row mb-5 article-container" v-for="post in posts">
      <div class="col-12 col-md-5 my-md-auto mb-3 mb-md-0">
        <router-link :to="'/' + post.category.slug + '/' + post.slug">
          <img
            :src="'https://storage.googleapis.com/amchara-205620.appspot.com/storage/' + post.featured_image"
            :alt="post.title"
            width="100%"
          />
        </router-link>
      </div>
      <div class="col-12 col-md-7 my-auto pl-4 pl-md-0">
        <div class="text-content mb-0">
          <h4 class="mt-0">
            <router-link
              :to="'/' + post.category.slug + '/' + post.slug"
              style="text-decoration: none"
              >{{ post.title }}</router-link
            >
          </h4>

          <p class="mb-0">
            <router-link
              :to="'/' + post.category.slug + '/' + post.slug"
              style="text-decoration: none; color: #868686"
              >{{ post.seo_description }}</router-link
            >
          </p>
        </div>
        <router-link
          class="btn btn-outline-blue btn-sm mt-2"
          :to="'/' + post.category.slug + '/' + post.slug"
          >Read More</router-link
        >
      </div>
    </div>
    <div class="row text-center mb-5">
      <div class="col">
        <button @click="offset += 4" class="btn load-more am-btn-blue">
          Load More
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["category"],
  data: function () {
    return {
      posts: [],
      offset: 0,
    };
  },
  created() {
    this.getPostsByCategory(this.category);
  },
  methods: {
    getPostsByCategory(category) {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/blog/categories/" +
            category +
            "/fetch-posts?offset=" +
            this.offset
        )
        .then((response) =>
          response.data.posts.forEach((item) => this.posts.push(item))
        );
    },
    formatBackgroundUrl(url) {
      return `background: url('${url}'`;
    },
  },
  watch: {
    offset() {
      this.getPostsByCategory(this.category);
    },
  },
};
</script>