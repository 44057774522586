<template>
  <div>
    <!-- Blog Categories -->
    <div
      class="container my-5 blog-category"
      id="blog-category"
      v-if="category"
    >
      <hr class="px-0 mt-0 mx-0" />
      <div class="row">
        <div class="col-md-8 pl-md-4">
          <div class="row blog-breadcrumbs">
            <div class="col">
              <span class="">
                <router-link class="me-2" to="/blog">Home</router-link></span
              >
              <span class="me-2">></span>
              <span class="">
                <router-link class="me-2" to="/blog">Blog</router-link></span
              >
              <span class="me-2">></span>
              <span class="">
                <router-link class="me-2" :to="'/' + category.slug">{{
                  category.name
                }}</router-link></span
              >
            </div>
          </div>
          <!-- Blog Title -->
          <div class="title-section row mt-4 pb-2">
            <h1 id="page-title" class="text-dark post-title">
              {{ category.name }}
            </h1>
          </div>

          <!-- Main Blog Content -->
          <div class="row pt-5" id="category-blog-posts">
            <div class="col">
              <category-blog-posts
                :category="category.slug"
              ></category-blog-posts>
            </div>
          </div>
        </div>
        <div class="col-md-3 offset-md-1 blog-sidebar">
          <side-bar
            :related-content="relatedContent"
            :categories="categories"
          ></side-bar>
        </div>
      </div>
    </div>

    <div v-else>Loading</div>

    <div class="d-sm-block d-md-none">
      <blog-search></blog-search>
    </div>

    <!-- Newsletter signup -->
    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import BlogSearch from "./blog-partials/BlogSearch";
import CategoryBlogPosts from "./blog-partials/CategoryBlogPostsComponent";
import NewsletterCta from "../pages/page-partials/NewsletterCta";
import SideBar from "./blog-partials/SideBar";

export default {
  // props: ["category", "relatedContent", "categories"],
  data() {
    return {
      category: null,
      relatedContent: null,
      categories: [],
    };
  },
  methods: {
    fetchCategory() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/blog/categories/" +
            this.$route.params.cat
        )
        .then(({ data }) => {
          this.category = data.category;
        });
    },
  },
  created() {
    this.$store.isBlogPost = true;
  },
  beforeDestroy() {
    this.$store.isBlogPost = false;
  },
  mounted() {
    this.fetchCategory();
  },
  components: {
    BlogSearch,
    NewsletterCta,
    SideBar,
    CategoryBlogPosts,
  },
};
</script>

<style>
</style>